






























import Vue from "vue";
import Counters from "./components/Counters.vue";

export default Vue.extend({
  name: "App",

  components: {
    Counters
  },

  data: () => ({
    //
  })
});
