import * as constants from "../client/constants";

export default class Player {
  name: string;
  score: number;
  deck: object;
  background: string;

  constructor() {
    this.name = "";
    this.score = 20;
    this.deck = {};
    this.background = constants.playerBackgrounds[0].value;
  }
}
