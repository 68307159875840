







































































/* eslint-disable */
import Vue from "vue";
import Counter from "./Counter.vue";
import Player from "../models/Player";
import * as constants from "@/client/constants";

export default Vue.extend({
  name: "Counters",
  components: {
    Counter
  },
  data() {
    return {
      model: new Player(),
      players: [],
      dialog: false,
      temp: 0,
      message: "",
      newName: "",
      background: "#231719",
      items: constants.playerBackgrounds
    };
  },
  methods: {
    addPlayer() {
      this.model.background = this.background;
      this.model.name = this.newName;
      this.$store
        .dispatch("addPlayer", this.model)
        .then(() => {
          this.message = "Player added successfully";
          this.dialog = false;
          this.newName = "";
          this.background = "#231719";
          setTimeout(() => {
            this.message = "";
          }, 1500);
        })
        .catch(error => {
          console.error(error);
        });

      // @ts-ignore
      this.players.push(this.model);
      this.model = new Player();
    },
    removePlayer(player: Player) {
      this.$store.dispatch("removePlayer", player).catch(error => {
        console.error(error);
      });

      this.players = this.players.filter(
        // @ts-ignore
        filtered => filtered.name !== player.name
      );
    }
  },
  computed: {
    numCols() {
      return this.$data.players.length > 1 ? 6 : 12;
    }
  }
});
