export const playerBackgrounds = [
  {
    text: "Red",
    value: "#7f1e1c"
  },
  {
    text: "Black",
    value: "#0c0c0c"
  },
  {
    text: "Blue",
    value: "#171a2e"
  },
  {
    text: "Pink",
    value: "#A10242"
  },
  {
    text: "Brown",
    value: "#231719"
  },
  {
    text: "Green",
    value: "#122B28"
  }
];
