import Vue from "vue";
import Vuex from "vuex";
import Player from "../models/Player";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    players: Array<Player>()
  },

  mutations: {
    addPlayer(state: any, player: Player) {
      state.players.push(player);
    },
    removePlayer(state: any, player: Player) {
      state.players = state.players.filter((filtered: any) => {
        return filtered.name !== player.name;
      });
    }
  },
  actions: {
    addPlayer(context: any, player: Player) {
      return new Promise(resolve => {
        context.commit("addPlayer", player);
        resolve();
      });
    },
    removePlayer(context: any, player: Player) {
      return new Promise(resolve => {
        context.commit("removePlayer", player);
        resolve();
      });
    }
  }
});

//https://medium.com/@RupaniChirag/vuex-with-typescript-b83a62aa48a8
