





















































import Vue from "vue";
import store from "../store/store";

export default Vue.extend({
  name: "Counters",
  data() {
    return {
      multiplier: 1
    };
  },
  props: {
    player: Object,
    numCols: Number
  },
  methods: {
    increase(player: any, multiplier: number) {
      player.score += multiplier;
    },
    decrease(player: any, multiplier: number) {
      player.score -= multiplier;
    },
    toggleMultiplier(): any {
      this.multiplier = this.multiplier === 1 ? 10 : 1;
    },
    removePlayer() {
      this.$emit("remove", this.player);
    }
  }
});
